import React, { useState, lazy, Suspense, useRef } from 'react';
import logo from './Assets/logo.svg';
import logoWhite from './Assets/logo_white.svg';
import Slider from 'react-slick';
import './LandingPage.css';
import { slide as Menu } from 'react-burger-menu'

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Food logos
import logoBurgerboss from './Assets/Logos/burgerboss.svg';
import logoSlaai from './Assets/Logos/slaai.svg';
import logoFirebirds from './Assets/Logos/firebirds.svg';
import logoPizzaCo from './Assets/Logos/pizza_co.svg';
import logoChicagos from './Assets/Logos/chicagos.svg';
import logoPokePanda from './Assets/Logos/poke_panda.svg';
import logoSnackSnack from './Assets/Logos/snack_snack.svg';

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link
} from 'react-router-dom';


const Search = lazy(() => import('./Components/Search/Search'));
const FAQ = lazy(() => import('./Components/FAQ/FAQ'));
const Privacy = lazy(() => import('./Components/Privacy/Privacy'));


const burgerStyles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '36px',
    height: '30px',
    right: '6%',
    top: '22px'
  },
  bmBurgerBars: {
    background: '#000'
  },
  bmBurgerBarsHover: {
    background: '#a90000'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmCross: {
    background: '#bdc3c7'
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%'
  },
  bmMenu: {
    background: '#ffffff',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em'
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em'
  },
  bmItem: {
    display: 'inline-block',
    fontSize: '22px',
    fontWeight: 'bold',
    color: 'black',
    textAlign: 'left',
    width: '100%',
    marginTop: '15px',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)'
  }
}

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'none', right: '-40px' }}
      onClick={onClick}
    />
  );
}

function PreviousArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'none', left: '-40px' }}
      onClick={onClick}
    />
  );
}


const logoSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  focusOnSelect: true,
  nextArrow: <NextArrow/>,
  prevArrow: <PreviousArrow />,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 2,
        initialSlide: 0
      }
    },
  ]
};



function LandingPage() {
  const [burgerIsOpen, setBurgerIsOpen] = useState(false);

  // The "Secret" buttons need to be kept at the top level component so that the menu can be accessed in multiple
  // places
  const menuConstantia = useRef(null);
  const menuRondebosch = useRef(null);
  const menuGardens = useRef(null);

  const menuItemClick = () => {
    setBurgerIsOpen(false);
  }

  return (
    <div>
      <button
        className="secret-button" ref={menuConstantia}
        data-glf-cuid="e1de202a-ab04-4adc-89a2-be3e0b471db6"
        data-glf-ruid="d5f1cf3f-1eba-4a4d-a24e-6ae09df2d747"
      />
      <button
        className="secret-button" ref={menuRondebosch}
        data-glf-cuid="e1de202a-ab04-4adc-89a2-be3e0b471db6"
        data-glf-ruid="e3352cea-3bdf-442b-adb2-295ff6eb9b6c"
      />
      <button
        className="secret-button" ref={menuGardens}
        data-glf-cuid="e1de202a-ab04-4adc-89a2-be3e0b471db6"
        data-glf-ruid="9af76659-3039-4c6c-9d5e-9d404e5035fe"
      />
      <Router>
        <Menu styles={burgerStyles} right isOpen={burgerIsOpen} onOpen={() => setBurgerIsOpen(true)}
              onClose={() => setBurgerIsOpen(false)}>
          <Link to="/" onClick={menuItemClick}>
            <img className="eater-burger-logo" src={logoWhite} alt="logo" />
          </Link>
          <Link to="/" onClick={menuItemClick}>Home</Link>
          <Link to="/PRIVACY" onClick={menuItemClick}>Privacy Policy</Link>
          <Link to="/FAQ" onClick={menuItemClick}>FAQ</Link>
        </Menu>
        <div className="navbar">
          <Link to="/">
            <img className="eater-logo" src={logo} alt="logo" />
          </Link>
        </div>
        <div className="background" />
        <div className="content-container">
          <Suspense fallback={null}>
            <Switch>
              <Route
                exact
                path="/"
                render={(props) => (
                  <Search {...props} menuRondebosch={menuRondebosch} menuConstantia={menuConstantia} menuGardens={menuGardens} />
                )}
              />
              <Route path="/FAQ" component={FAQ}/>
              <Route
                path="/Privacy"
                render={(props) => (
                  <Privacy {...props} menuRondebosch={menuRondebosch} menuConstantia={menuConstantia} menuGardens={menuGardens} />
                )}
              />
            </Switch>
          </Suspense>
          <div className="eater-brand-carousel-wrapper">
            <Slider {...logoSettings} className="eater-brand-carousel">
              <div>
                <Link to="/menus">
                  <img className="eater-brand-logo" src={logoBurgerboss} alt="logo" />
                </Link>
              </div>
              <div>
                <Link to="/menus">
                  <img className="eater-brand-logo" src={logoSlaai} alt="logo" />
                </Link>
              </div>
              <div>
                <Link to="/menus">
                  <img className="eater-brand-logo" src={logoFirebirds} alt="logo" />
                </Link>
              </div>
              <div>
                <Link to="/menus">
                  <img className="eater-brand-logo" src={logoPizzaCo} alt="logo" />
                </Link>
              </div>
              <div>
                <Link to="/menus">
                  <img className="eater-brand-logo" src={logoChicagos} alt="logo" />
                </Link>
              </div>
              <div>
                <Link to="/menus">
                  <img className="eater-brand-logo" src={logoPokePanda} alt="logo" />
                </Link>
              </div>
              <div>
                <Link to="/menus">
                  <img className="eater-brand-logo" src={logoSnackSnack} alt="logo" />
                </Link>
              </div>
            </Slider>
          </div>
        </div>
      </Router>
    </div>
  );
}

export default LandingPage;
